import AuthLayout from "components/shared/AuthLayout";
import Logo from "components/shared/Logo";
import { T, useTranslate } from "lib/translate";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useFetch, { getFormBodyObject } from "../../lib/fetch-utils";
import Alert from "components/shared/Alert";
import { PrimaryButton, SecondaryButton } from "components/shared/button";
import { Text } from "components/shared/Fields";
import { emailValidator, passwordValidator, useForm } from "lib/hooks/form";
import clientConfig from "../../lib/client_config";
import { urlValidator } from "lib/validators";
import AgreeToPolicies from "components/AgreeToPolicies";
import { Helmet } from "react-helmet";

const SignUpForm = () => {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const { performApiAction } = useFetch();
  const [error, setError] = useState("");
  const form = useForm<
    | "firstname"
    | "lastname"
    | "email"
    | "password"
    | "agreeToPolicies"
    | "orgname"
    | "website"
  >();
  const [currentStep, setCurrentStep] = useState(0);
  const [showError, setShowError] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const firstNameProps = form.useRegister({
    name: "firstname",
    label: t("general.form.label.firstname"),
    type: "text",
    autoComplete: "given-name",
  });
  const lastNameProps = form.useRegister({
    name: "lastname",
    label: t("general.form.label.lastname"),
    type: "text",
    autoComplete: "family-name",
  });
  const emailProps = form.useRegisterEmail("email");

  const passwordProps = form.useRegister({
    name: "password",
    label: t("general.form.label.password"),
    type: "password",
    validator: passwordValidator,
    autoComplete: "new-password",
    errorText: t("general.form.error.invalid-password"),
  });

  const agreeToPoliciesProps = form.useRegister({
    name: "agreeToPolicies",
    type: "switch",
    validator: (value) => !!value,
    errorText: t("general.form.error.agree-to-policies"),
  });

  const orgNameProps = form.useRegister({
    name: "orgname",
    label: t("general.form.label.orgname"),
    type: "text",
    autoComplete: "org-name",
  });

  const websiteProps = form.useRegister({
    name: "website",
    label: t("general.form.label.website"),
    type: "text",
    autoComplete: "website",
    validator: (x) => urlValidator(x),
    errorText: t("errors.url"),
  });

  form.setOnSubmit(async (event: React.SyntheticEvent) => {
    try {
      const signUpResponse = await performApiAction({
        url: `${clientConfig.edgeApi}/api/v1/users/signup`,
        newData: {
          user: {
            firstname: firstNameProps.value,
            lastname: lastNameProps.value,
            email: emailProps.value,
            password: passwordProps.value,
          },
          org: {
            name: getFormBodyObject(event.target as HTMLFormElement).orgname,
            website: getFormBodyObject(event.target as HTMLFormElement).website,
          },
        },
        resultNeeded: true,
      });

      if (signUpResponse.ok) {
        setSuccessMessage(t("signup-success-message"));
        setTimeout(() => {
          navigate("/app/login");
        }, 5000);
      } else {
        setError(t(signUpResponse.error));
      }
    } catch (e) {
      setError(t("general.form.error.unknown"));
    }
  });
  return (
    <div>
      <Helmet>
        <title>
          {t("signup.title") + t("general.title-separator") + t("vettify")}
        </title>
        <meta name="description" content={t("signup.meta.description")} />
      </Helmet>
      <div className="grow-0"></div>
      <AuthLayout>
        <div>
          <a href="https://vettify.io/" aria-label={"landing page"}>
            <Logo className="mb-10 h-10 w-auto" />
          </a>
          <h2 className="text-lg font-semibold text-gray-900">
            <T keyName="signup.title" />
          </h2>
          <p className="mt-2 text-sm text-gray-700">
            <T keyName="signup.subtitle-before" />{" "}
          </p>
          <Link
            to="/app/login"
            className="text-xs font-medium text-vettiblue-500 hover:underline">
            <T keyName="signup.subtitle-link" />
          </Link>
        </div>
        <form onSubmit={form.submit} className="mt-10 grid grid-cols-1 gap-y-4">
          {currentStep === 0 && (
            <>
              <Text
                {...firstNameProps}
                className="col-span-full"
                errorText={
                  showError && !firstNameProps.value
                    ? t("signup-name-field-error")
                    : ""
                }
              />
              <Text
                {...lastNameProps}
                className="col-span-full"
                errorText={
                  showError && !lastNameProps.value
                    ? t("signup-name-field-error")
                    : ""
                }
              />
              <Text
                {...emailProps}
                className="col-span-full"
                errorText={
                  showError && !emailValidator(emailProps.value)
                    ? t("signup-error-email")
                    : ""
                }
              />
              <Text
                {...passwordProps}
                className="col-span-full"
                errorText={
                  showError && !passwordValidator(passwordProps.value)
                    ? t("general.form.error.invalid-password")
                    : ""
                }
              />
              <div className="col-span-full">
                <AgreeToPolicies
                  {...agreeToPoliciesProps}
                  errorText={
                    showError && !agreeToPoliciesProps.value
                      ? t("general.form.error.agree-to-policies")
                      : ""
                  }
                />
              </div>
            </>
          )}
          {currentStep === 1 && (
            <>
              <Text {...orgNameProps} className="col-span-full" />
              <Text {...websiteProps} className="col-span-full" />
            </>
          )}
          {currentStep === 0 && (
            <div className="flex justify-start mt-10">
              <PrimaryButton
                onClick={() => {
                  if (
                    !firstNameProps.value ||
                    !lastNameProps.value ||
                    !emailValidator(emailProps.value) ||
                    !passwordValidator(passwordProps.value) ||
                    !agreeToPoliciesProps.value
                  ) {
                    setShowError(true);
                    return;
                  }
                  setCurrentStep(1);
                }}
                text={t("signup-step-one-button-text")}
                styleOverride="mb-0 px-4 py-2"
              />
            </div>
          )}
          {currentStep === 1 && (
            <div className="flex justify-between mt-10">
              <SecondaryButton
                text={t("signup-step-two-back-button-text")}
                onClick={() => setCurrentStep(0)}
                styleOverride="mb-0 px-6 py-2"
              />
              <PrimaryButton
                text={t("sign-up.form.submit")}
                type="submit"
                styleOverride="mb-0 px-6 py-2"
              />
            </div>
          )}
          {error && <Alert type="error">{error}</Alert>}
          {successMessage && <Alert type="success">{successMessage}</Alert>}
        </form>
      </AuthLayout>
      <div className="grow-0"></div>
    </div>
  );
};

export default SignUpForm;
